html {
  background-color: white;
  font-family: "FearlessMedium", sans-serif;
  text-align: center;
  cursor: url(../src/assets/img/United_States_ofAmericaFlagCursor.png), auto !important;
}

body{
  background-image: url(../src/assets/img/200w.gif);
  background-size: cover;
  
  
  
  height: 100vh;
  padding:0;
  margin:0;
}

H1{

  font-family: "FearlessMedium", sans-serif;
  font-size: 24px;
  letter-spacing: 0.37px;
  line-height: 85.58%;
  text-transform: uppercase;
}

body {
  color: white !important;
  padding: 40px;
}

#root {
  background-color: #164fd7;
}

.footer {
  /* position: fixed; */
  width: 100%;
  bottom: 0;
}

.social-icon{
  width: 32px;
  border-radius: 8px;
  margin: 4px;
  filter: grayscale(1);
}